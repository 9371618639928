import styled from 'styled-components';
import { Box } from '../../atoms';

const Blackout = styled.div`
  opacity: 0.25;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  transition: 0.5s ease;
`;

const CityInfoWrapper = styled.div``;

const CityWrapper = styled(Box)`
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  max-height: 250px;

  img {
    width: 100%;
    transition: 0.5s ease;
  }

  &:hover {
    ${Blackout} {
      opacity: 0.5;
    }

    img {
      transform: scale(1.15);
    }
  }

  ${CityInfoWrapper} {
    opacity: 0;
    transition: 0.1s ease;
  }

  .lazy-load-image-loaded + ${CityInfoWrapper} {
    opacity: 1;
  }

  .lazyload-img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
`;

const CityInfo = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

export { Blackout, CityWrapper, CityInfo, CityInfoWrapper };
