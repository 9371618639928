import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Title } from '@firsttable/web-components/atoms';
import { getUser, isBrowser } from '@firsttable/functions';
import { flowRight as compose } from 'lodash';
import HeroBackgroundImage from '../../molecules/HeroBackgroundImage';
import HomeSearchForm from '../Forms/HomeSearchForm';
import AuthContext from '../../../context/AuthContext';
import mrBird from './mrbird.svg';
import { withCitySearchFilters } from '../../../hocs';

const HomeHero = ({ regions, restaurantSearch }) => {
  const title = 'The early bird gets the dinner deal';
  const { isLoggedIn } = useContext(AuthContext);
  const user = getUser();
  const userCity =
    isBrowser && window.localStorage.getItem('city')
      ? window.localStorage.getItem('city')
      : null;
  const city = user?.city ? user.city.slug : userCity;
  const session = restaurantSearch?.session
    ? restaurantSearch.session
    : 'dinner';

  return (
    <HeroBackgroundImage>
      <Container>
        <Title
          textAlign="center"
          mb={['l', 80]}
          mx="auto"
          size="hero"
          as="h1"
          color="white"
          maxWidth={700}
        >
          {title}
        </Title>
        <Box position="relative">
          <Box
            display={['none', 'block']}
            position="absolute"
            bottom="100%"
            left={[0, null, 40]}
          >
            <img src={mrBird} alt="Mr. Bird" />
          </Box>
          <HomeSearchForm
            subCities={regions}
            isLoggedIn={isLoggedIn}
            initialValues={{ selectedDate: 'any', city, session }}
          />
        </Box>
      </Container>
    </HeroBackgroundImage>
  );
};

HomeHero.propTypes = {
  regions: PropTypes.object,
  restaurantSearch: PropTypes.object,
};

HomeHero.defaultProps = {
  regions: {},
  restaurantSearch: {
    session: 'dinner',
  },
};

export default compose(withCitySearchFilters)(HomeHero);
