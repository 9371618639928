import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Container, Title, Text, Button } from '../../atoms';
import cutlery01 from './cutlery-01.svg';
import cutlery02 from './cutlery-02.svg';

const Wrapper = styled(Box)`
  background: url('${cutlery01}') no-repeat calc(50% + 680px) calc(100% + 70px),
    url('${cutlery02}') no-repeat calc(50% - 725px) -90px,
    linear-gradient(180deg, #f5f9fc, #f5f9fc);
`;

const Covid19 = ({ NavButtonComponent }) => (
  <Wrapper py={[35, null, 90]} mb={[65, 100]}>
    <Container>
      <Box maxWidth={[null, 780]} mx="auto" textAlign="center">
        <Title mb={[20, null, 'l']}>Covid-19 update</Title>
        <Text fontSize={[null, null, 'xl']} mb={['l', 'xl']}>
          Learn more about how you can support local restaurants when using{' '}
          First Table and get the latest on our Covid-19 updates and FAQs.
        </Text>
        <Box maxWidth={180} mx="auto">
          <Button NavComponent={NavButtonComponent} to="/covid-19/" wide>
            Learn more
          </Button>
        </Box>
      </Box>
    </Container>
  </Wrapper>
);

Covid19.propTypes = {
  NavButtonComponent: PropTypes.any.isRequired,
};

export default Covid19;
