import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { awsImage } from '@firsttable/functions';
import { Box, Container, Row, Col, Title, Link } from '../../atoms';
import { Blackout, CityWrapper, CityInfo, CityInfoWrapper } from './styles';

const FeaturedCities = ({ states, NavLinkComponent }) => {
  const space = 85;
  const imgCover = (cover, highRes = false) => {
    const imgWidth = 300;
    const imgHeight = Number((imgWidth / 1.135).toFixed());
    return awsImage(
      cover,
      highRes ? imgWidth * 2 : imgWidth,
      highRes ? imgHeight * 2 : imgHeight
    );
  };
  return (
    <Container>
      <Row>
        {states.edges.map(({ node: { id, title, cities } }) => {
          // default widths
          const mobileStateWidth = 1;
          let tabletStateWidth = 1;
          let desktopStateWidth = 1;
          let mobileCityWidth = 1 / 2;
          const tabletCityWidth = 1 / 3;
          let desktopCityWidth = 1 / 4;

          if (cities.length === 1) {
            tabletStateWidth = 1 / 2;
            desktopStateWidth = 1 / 3;
            mobileCityWidth = 1;
          }

          if (cities.length <= 5) {
            desktopCityWidth = 1 / cities.length;
          }

          return (
            <Col
              mb={['xl', space - 20]}
              width={[
                mobileStateWidth,
                tabletStateWidth,
                desktopStateWidth,
                desktopStateWidth,
              ]}
              key={id}
            >
              <Title
                mb={['xl', 56]}
                textAlign="center"
                size={desktopStateWidth === 1 ? 'h2' : 'h4'}
              >
                {title}
              </Title>
              <Row gap={['s', 20]}>
                {cities.map(
                  ({
                    id: cityId,
                    menuTitle,
                    slug,
                    scenicPhoto: { basePath: cover },
                  }) => (
                    <Col
                      key={cityId}
                      mb={['s', 20]}
                      width={[
                        mobileCityWidth,
                        mobileCityWidth,
                        tabletCityWidth,
                        desktopCityWidth,
                      ]}
                      textAlign="center"
                    >
                      <Link NavComponent={NavLinkComponent} to={slug}>
                        <CityWrapper>
                          <Box className="bg-pulse-animation" pb="88%" />
                          <LazyLoadImage
                            wrapperClassName="lazyload-img-wrapper"
                            srcSet={`${imgCover(cover)} 1x, ${imgCover(
                              cover,
                              true
                            )} 2x`}
                            src={imgCover(cover)}
                            effect="opacity"
                            delayTime={5000}
                          />
                          <CityInfoWrapper>
                            <Blackout />
                            <CityInfo px="m">
                              <Box>
                                <Title
                                  as="h3"
                                  fontSize={['22px', '32px']}
                                  color={null}
                                  mb="xs"
                                >
                                  {menuTitle}
                                </Title>
                              </Box>
                            </CityInfo>
                          </CityInfoWrapper>
                        </CityWrapper>
                      </Link>
                    </Col>
                  )
                )}
              </Row>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

FeaturedCities.propTypes = {
  states: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          title: PropTypes.string,
          cities: PropTypes.arrayOf(
            PropTypes.shape({
              menuTitle: PropTypes.string,
              slug: PropTypes.string,
              restaurantCount: PropTypes.number,
              scenicPhoto: PropTypes.object,
            })
          ),
        }),
      })
    ),
  }),
  NavLinkComponent: PropTypes.any.isRequired,
};

FeaturedCities.defaultProps = {
  states: [],
};

export default FeaturedCities;
