import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import media from 'styled-media-query';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import Box from '@firsttable/web-components/atoms/Box';
import Container from '@firsttable/web-components/atoms/Container/Container';
import Link from '@firsttable/web-components/atoms/Link/Link';
import Text from '@firsttable/web-components/atoms/Text/Text';
import { slugify } from '@firsttable/functions';
import glassesImg from './glasses.svg';
import lightsImg from './lights.svg';
import { countryISOCode } from '../../../helpers/data';
import NavLinkComponent from '../../atoms/NavLink';

const Wrapper = styled(Box)`
  position: relative;
  background: url('${lightsImg}') no-repeat calc(50% - 700px) -70px,
    url('${glassesImg}') no-repeat calc(50% + 750px) calc(100% + 70px),
    linear-gradient(180deg, #fbf6f0 27.52%, #fff 100%);
`;

const StyledUl = styled.ul`
  list-style: none;
  margin: 0;
  columns: 4;
  position: relative;
  z-index: 20;
  padding: 100px;
  ${space};
  ${media.lessThan(bp.m)`
    column-gap: 20px;
  `};
  ${media.lessThan(bp.s)`
    column-count: 2;
    column-gap: 50px;
  `};
  li {
    margin-bottom: 10px;
  }
`;

const CountryHeading = styled(Text)`
  font-weight: bold;
  font-size: ${themeGet('fontSizes.l')}px;
  color: ${themeGet('colors.brand.blue')};
`;

const HomeCities = () => {
  const countryCode = countryISOCode();
  const { cityList } = useStaticQuery(graphql`
    query getFooterCities {
      cityList: allCitiesJson {
        edges {
          node {
            id
            countryCode
            footerLinks {
              country
              domain
              regions {
                slug
                title
              }
            }
          }
        }
      }
    }
  `);
  const filteredCountryList = cityList.edges.filter(
    ({ node }) => node.countryCode === countryCode,
  );
  const [list] = filteredCountryList;
  const { node: cities } = list;
  return (
    <Wrapper>
      <Container maxWidth={1290}>
        <Box maxWidth={bp.l} ml="auto">
          <StyledUl p={['50px 0', '120px 0']}>
            {cities.footerLinks.map((link, i) => (
              <Fragment key={`${i}_country`}>
                <li css="&:first-child p { margin-top: 0; }">
                  <CountryHeading mt="l" mb="s">
                    {link.country}
                  </CountryHeading>
                </li>
                {link.regions.map((region) => {
                  const { title, slug } = region;
                  let urlSegment = '';
                  if (slug === null) {
                    urlSegment = `/${slugify(title)}/`;
                  } else {
                    urlSegment = region.slug;
                  }
                  let slugNode = (
                    <Link NavComponent={NavLinkComponent} to={urlSegment}>
                      {title}
                    </Link>
                  );
                  if (link.domain) {
                    slugNode = (
                      <Link href={`${link.domain}${urlSegment}`}>{title}</Link>
                    );
                  }
                  return <li key={`${i}_${urlSegment}`}>{slugNode}</li>;
                })}
              </Fragment>
            ))}
          </StyledUl>
        </Box>
      </Container>
    </Wrapper>
  );
};

HomeCities.propTypes = {
  // regions: PropTypes.object.isRequired,
};
HomeCities.defaultProps = {};

export default HomeCities;
