import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Container, Row, Col, Icon, Link, Flex, Text,
} from '@firsttable/web-components/atoms';
import RowHead from '@firsttable/web-components/molecules/RowHead';
import ThemeLayout from './layout';
import NavLinkComponent from '../components/atoms/NavLink';

const GlobalLayout = ({ showGiftVoucherLinks }) => {
  const countries = [
    { url: 'https://www.firsttable.co.nz', title: 'New Zealand' },
    { url: 'https://www.firsttable.com.au', title: 'Australia' },
    { url: 'https://ca.firsttable.com', title: 'Canada' },
    { url: 'https://www.firsttable.co.uk', title: 'United Kingdom' },
    { url: 'https://www.firsttable.ie', title: 'Ireland' },
    { url: 'https://www.firsttable.co.id', title: 'Bali' },
  ];

  return (
    <ThemeLayout>
      <Flex alignItems="center" justifyContent="center" height="100vh" m={0}>
        <Container>
          {showGiftVoucherLinks && <RowHead title="Gift Vouchers" subTitle="Please select your country" />}
          <Row>
            {countries.map((country) => (
              <Col width={[1, 1 / 3]} key={country.url}>
                <Link
                  NavComponent={NavLinkComponent}
                  to={`${country.url}${showGiftVoucherLinks ? '/gift-vouchers' : ''}`}
                  aria-label={`Visit ${country.title}`}
                  color="brand.blue"
                >
                  <Box textAlign="center" mt={20}>
                    <Icon
                      name="logo-bird"
                      top="2px"
                      mr={10}
                    />
                    <Icon
                      name="logo"
                      top="2px"
                    />
                    <Text fontSize={24} m="20px 0">{country.title}</Text>
                  </Box>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </Flex>
    </ThemeLayout>
  );
};

GlobalLayout.propTypes = {
  showGiftVoucherLinks: PropTypes.bool,
};
GlobalLayout.defaultProps = {
  showGiftVoucherLinks: false,
};

export default GlobalLayout;
