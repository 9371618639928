import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Field, withFormik } from 'formik';
import { flowRight as compose } from 'lodash';
import { navigate } from 'gatsby';
import * as Yup from 'yup';
import Form from '@firsttable/web-components/organisms/Forms/Form';
import { Box, Divider, Icon } from '@firsttable/web-components/atoms';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import Button from '@firsttable/web-components/atoms/Button';
import {
  errorMessage,
  findATableEvent
} from '@firsttable/functions';
import { withAlert } from 'react-alert';
import { withCitySearchFilters, withLocation } from '../../../hocs';

const heights = {
  s: '54px',
  m: '90px',
  l: '110px',
};

const DatepickerBox = withCitySearchFilters(
  ({ name, onChange, updateSearchFiltersQuery }) => {
    const dateRange = [{ value: 'any', label: 'All dates' }];
    for (let i = 0; i <= 6; i += 1) {
      // todo get range for specific user
      const date = {};
      date.value = moment().add(i, 'days').format('YYYY-MM-DD');
      date.label = moment().add(i, 'days').format('ddd Do MMMM');

      dateRange.push(date);
    }

    return (
      <Box
        style={{ flexShrink: 0 }}
        position="relative"
        width={['100%', '20%', '230px']}
        mb={[3, 0]}
      >
        <Box
          display={['none', 'none', 'block']}
          zIndex={1}
          position="absolute"
          top="40px"
          left="32px"
          style={{
            pointerEvents: 'none',
          }}
        >
          <Icon name="m-calendar" />
        </Box>
        <Field
          component={FormField}
          id={name}
          name={name}
          type="select"
          pure
          styles={[
            { height: heights.s, padding: '0 10px 0 20px' },
            { height: heights.m, padding: '0 15px 0 30px' },
            { height: heights.l, padding: '0 25px 0 65px' },
          ]}
          options={dateRange}
          customOnChangeFunc={async (e) => {
            onChange(name, e.value);
            await updateSearchFiltersQuery({
              ...updateSearchFiltersQuery(),
              selectedDate: e.value,
            });
          }}
        />
      </Box>
    );
  },
);

DatepickerBox.propTypes = {
  name: PropTypes.string,
};

const LocationBox = ({ name }) => (
  <Box
    style={{ flexShrink: 0 }}
    position="relative"
    width={['100%', '20%', '230px']}
  >
    <Box
      display={['none', 'none', 'block']}
      zIndex={1}
      position="absolute"
      top="40px"
      left="40px"
      style={{
        pointerEvents: 'none',
      }}
    >
      <Icon name="m-near-me" />
    </Box>
    <Field
      component={FormField}
      type="select"
      id={name}
      name={name}
      pure
      styles={[
        { height: heights.s, padding: '0 10px 0 20px' },
        { height: heights.m, padding: '0 15px 0 30px' },
        { height: heights.l, padding: '0 25px 0 40px' },
      ]}
      options={[
        { value: 'rating', label: 'Top rated' },
        { value: 'distance', label: 'Near me' },
      ]}
    />
  </Box>
);

LocationBox.propTypes = {
  name: PropTypes.string.isRequired,
};

const CitySearchForm = ({
  handleSubmit,
  subCities,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
}) => {
  // canada only has dinner for now
  const searchSessions = [
    { value: 'dinner', label: 'Dinner' },
    { value: 'lunch', label: 'Lunch' },
    { value: 'breakfast', label: 'Breakfast' },
  ];
  if (+process.env.GATSBY_SITE_ID === 4) {
    searchSessions.splice(-2, 2);
  }
  return (
    <Form handleSubmit={handleSubmit}>
      <Box bg={[null, '#fff']} borderRadius={2} display={['block', 'flex']}>
        <DatepickerBox
          name="selectedDate"
          onChange={setFieldValue}
          onBlur={setFieldTouched}
        />
        <Divider
          height={[0, heights.m, heights.l]}
          width={['100%', '1px']}
          style={{ flexShrink: 0 }}
        />
        <Box width="100%" mb={[3, 0]}>
          <Field
            component={FormField}
            name="city"
            type="select"
            placeholder="Select a Region"
            inlineError={false}
            pure
            styles={[
              { height: heights.s, padding: '0 10px 0 20px' },
              { height: heights.m, padding: '0 15px 0 30px' },
              { height: heights.l, padding: '0 25px 0 40px' },
            ]}
            options={subCities.edges.map(({ node }) => ({
              value: node.slug,
              label: node.menuTitle,
            }))}
          />
        </Box>
        <Divider
          height={[0, heights.m, heights.l]}
          width={['100%', '1px']}
          style={{ flexShrink: 0 }}
        />
        <Box width="100%" mb={[6, 0]}>
          <Field
            component={FormField}
            type="select"
            name="session"
            pure
            styles={[
              { height: heights.s, padding: '0 10px 0 20px' },
              { height: heights.m, padding: '0 15px 0 30px' },
              { height: heights.l, padding: '0 25px 0 40px' },
            ]}
            options={searchSessions}
          />
        </Box>
        <Divider
          height={[0, heights.m, heights.l]}
          width="1px"
          style={{ flexShrink: 0 }}
        />
        <Box
          p={[0, '18px', '28px']}
          width={[null, null, '240px']}
          style={{ flexShrink: 0 }}
        >
          <Button
            wide
            kind="cta"
            type="submit"
            isLoading={isSubmitting}
            data-testid="search__button"
            size="l"
          >
            Find a table
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

CitySearchForm.propTypes = {
  initialValues: PropTypes.shape({
    restaurantSort: PropTypes.string,
    selectedDate: PropTypes.string,
    session: PropTypes.string,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  subCities: PropTypes.shape(),
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

CitySearchForm.defaultProps = {
  subCities: {},
  location: {
    pathname: '',
  },
};

const HomeSearchSchema = Yup.object().shape({
  city: Yup.string().required('Required'),
});

export default compose(
  withAlert(),
  withCitySearchFilters,
  withLocation,
  withFormik({
    enableReinitialize: true,
    validationSchema: HomeSearchSchema,
    mapPropsToValues: ({ initialValues, subCities }) => {
      const cityExists = subCities?.edges
        ? subCities.edges.filter(
            ({ node }) => node?.slug === initialValues?.city,
          ).length
        : false;
      const city = cityExists ? initialValues?.city : '';
      return {
        ...initialValues,
        city,
      };
    },
    handleSubmit: async (
      values,
      { setSubmitting, props: { updateSearchFiltersQuery, alert } },
    ) => {
      const { errors } = await updateSearchFiltersQuery({
        ...updateSearchFiltersQuery(),
        session: values.session,
        selectedDate: values.selectedDate,
        city: values.city,
      });

      const city = values.city ? values.city : '';

      const cityName = city
        .replace(/\//g, '')
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      findATableEvent({ values, city: cityName });

      window.localStorage.setItem('city', values.city);
      if (errors) {
        alert.error(errorMessage(errors, 'There was an error finding a table'));
      }
      setSubmitting(false);
      if (!errors && values.city.length) {
        await navigate(values.city);
      }
    },
  }),
)(CitySearchForm);
