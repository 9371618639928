import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Divider,
  Box,
  Icon,
  Text,
  Title,
  Col,
  Link,
  Button,
} from '@firsttable/web-components/atoms';
import { graphql, useStaticQuery } from 'gatsby';
import Flex from '@firsttable/web-components/atoms/Flex';
import NavButtonComponent from '../../atoms/NavButton';
import NavLinkComponent from '../../atoms/NavLink';
import ModalContext from '../../../context/ModalContext';
import SignUpLayout from '../Auth/SignUp';
import { countryData } from '../../../helpers/data';

export const JoinFirstTable = ({
  showModal,
  siteConfig,
  showRestaurantSignUpCTA,
  content,
  restaurantButtonText,
  signUpButtonText,
}) => (
  <Row>
    <Col
      textAlign={['center', 'center', 'left']}
      width={[1, 1, 1 / 2]}
      mb={['20px', '20px', 0]}
    >
      <Divider display={['block', 'block', 'none']} m={['50px 0']} />
      <Box
        maxWidth={[470, null, 'none']}
        mx="auto"
        position="relative"
        pl={[null, null, '72px']}
        borderLeft={[null, null, '1px solid']}
        borderColor={[null, null, 'gold.800']}
      >
        <Box
          position="absolute"
          top="-45px"
          left="-10px"
          display={['none', 'none', 'block']}
        >
          <Icon
            width="28px"
            name="cutlery"
            color="gold.800"
            viewBox="0 0 40 48"
          />
        </Box>
        <Title m={['0 0 20px', '0 0 20px', '0 0 50px']}>
          Join our community of food lovers!
        </Title>
        <Text fontSize="xl" mb={0}>
          {content}
        </Text>
      </Box>
    </Col>
    <Col
      width={[1, 1, 1 / 2]}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      display="flex"
    >
      <Flex>
        <Box minWidth="208px" mx="auto" mb="13px">
          <Button
            kind="cta"
            size="l"
            wide
            onClick={() =>
              showModal(
                <SignUpLayout isModal GDPRCountry={siteConfig.GDPRCountry} />,
                { type: 'signUp' },
              )
            }
            data-testid="signup__button"
          >
            {signUpButtonText}
          </Button>
          <Text color="grayscale.600" mb={0}>
            Or visit our{' '}
            <Link
              NavComponent={NavLinkComponent}
              to="/frequently-asked-questions/"
              color="brand.red"
            >
              FAQs
            </Link>
          </Text>
        </Box>
        {showRestaurantSignUpCTA && (
          <Box minWidth="208px" mx="auto" mb="13px" ml={10}>
            <Button
              NavComponent={NavButtonComponent}
              kind="cta"
              size="l"
              wide
              to={countryData.signupUrl}
            >
              {restaurantButtonText}
            </Button>
            <Text color="grayscale.600" mb={0}>
              Or visit{' '}
              <Link
                NavComponent={NavLinkComponent}
                to="/restaurants/"
                color="brand.red"
              >
                restaurateurs
              </Link>
            </Text>
          </Box>
        )}
      </Flex>
    </Col>
  </Row>
);

JoinFirstTable.propTypes = {
  siteConfig: PropTypes.shape(),
  showModal: PropTypes.func.isRequired,
  showRestaurantSignUpCTA: PropTypes.bool,
  content: PropTypes.string,
  restaurantButtonText: PropTypes.string,
  signUpButtonText: PropTypes.string,
};

JoinFirstTable.defaultProps = {
  showRestaurantSignUpCTA: false,
  content:
    'Sign up now to save money by dining early and discover fine dining destinations and casual-chic eateries with 50% off the food bill.',
  restaurantButtonText: 'Restaurant sign up',
  signUpButtonText: 'Sign up',
};

const JoinFirstTableConnected = (props) => {
  const { siteConfig } = useStaticQuery(graphql`
    query JoinCountQuery {
      siteConfig {
        restaurantCount
        GDPRCountry
      }
    }
  `);
  const { showModalFunc } = useContext(ModalContext);
  return (
    <JoinFirstTable
      showModal={showModalFunc}
      siteConfig={siteConfig}
      {...props}
    />
  );
};

JoinFirstTableConnected.propTypes = {};
JoinFirstTableConnected.defaultProps = {};

export default JoinFirstTableConnected;
