import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Container from '@firsttable/web-components/atoms/Container/Container';
import Col from '@firsttable/web-components/atoms/Col/Col';
import Row from '@firsttable/web-components/atoms/Row/Row';
import { space } from '@firsttable/web-theme';
import HomeInfo from '@firsttable/web-components/organisms/HomeInfo';
import FeaturedCities from '@firsttable/web-components/organisms/FeaturedCities';
import OurStory from '@firsttable/web-components/organisms/OurStory';
import {
  countFormat,
  currencyFormat,
  roundToClosest,
} from '@firsttable/functions';
import Covid19 from '@firsttable/web-components/organisms/Covid19';
import HomeCities from '../components/organisms/HomeCities/HomeCities';
import ellipse from '../images/ellipse-01.svg';
import JoinFirstTable from '../components/organisms/JoinFirstTable';
import SEO from '../components/seo';
import HomePageHero from '../components/organisms/HomePageHero';
import Footer from '../components/organisms/Footer';
import ThemeLayout from '../layouts/layout';
import AuthContext from '../context/AuthContext';
import { isGlobalSite, isMinorSite } from '../helpers/data';
import GlobalLayout from '../layouts/GlobalLayout';
import NavLink from '../components/atoms/NavLink';
import NavButton from '../components/atoms/NavButton';

const HomePage = ({
  data: { allRegions, home, allCountryState, siteConfig },
}) => {
  const { isLoggedIn } = useContext(AuthContext);
  if (isGlobalSite) {
    return <GlobalLayout />;
  }
  const homePageData = home;

  let bookingMessage = `Book a First Table for ${currencyFormat(
    siteConfig.bookingPrice,
    siteConfig.currency,
  )} and get 50% off the food bill for two, three or four people`;
  if (siteConfig.siteId === 12) {
    bookingMessage =
      'Book a First Table for Rp50k or Rp100k and get 50% off the food bill for two, three or four people';
  }

  return (
    <ThemeLayout footer={<Footer />}>
      <SEO
        id={homePageData.foreignId}
        title={homePageData.metaTitleFormatted}
        description={homePageData.metaDescription}
        ogImage={homePageData.ogImage}
        metaTags={homePageData.metaTags}
        slug="/"
      />
      <HomePageHero regions={allRegions} />
      <Container mb={[65, 100]}>
        <Row>
          <Col width={[1, 1 / 3]} mb={['35px', 0]}>
            <HomeInfo
              icon="discovery"
              title="Find a restaurant"
              message={`Find a new restaurant to try from over ${countFormat(
                roundToClosest(siteConfig.restaurantCount, 100),
              )} restaurants globally`}
            />
          </Col>
          <Col width={[1, 1 / 3]} mb={['35px', 0]}>
            <HomeInfo
              icon="reviews"
              title="Select your date and time"
              message="Get in quick to score the ‘first table’ of breakfast, lunch or dinner"
            />
          </Col>
          <Col width={[1, 1 / 3]}>
            <HomeInfo
              icon="discount"
              title="Book a First Table"
              message={bookingMessage}
            />
          </Col>
        </Row>
      </Container>
      {!isMinorSite && <Covid19 NavButtonComponent={NavButton} />}
      <FeaturedCities states={allCountryState} NavLinkComponent={NavLink} />
      <span>
        {!isLoggedIn() && (
          <Container
            mb={['50px', '50px', 0]}
            background={[null, null, `url(${ellipse}) no-repeat 0 50%`]}
            pt={[null, null, '175px']}
            pb={[null, null, '175px']}
            pl={[space.s, space.m, '135px']}
          >
            <JoinFirstTable />
          </Container>
        )}
      </span>
      <OurStory NavButtonComponent={NavButton} />
      <HomeCities />
    </ThemeLayout>
  );
};

HomePage.defaultProps = {};

HomePage.propTypes = {
  data: PropTypes.shape({
    allRegions: PropTypes.shape(),
    home: PropTypes.shape(),
    comingSoon: PropTypes.shape(),
    homeRedirectorPage: PropTypes.shape(),
    restaurantRegion1: PropTypes.shape(),
    restaurantRegion2: PropTypes.shape(),
    restaurantRegion3: PropTypes.shape(),
    region1: PropTypes.shape(),
    region2: PropTypes.shape(),
    region3: PropTypes.shape(),
    siteConfig: PropTypes.shape(),
    allCountryState: PropTypes.shape(),
  }).isRequired,
};

export default HomePage;

export const query = graphql`
  query allRegionsHome {
    allRegions: allCity(filter: { parentId: { eq: 0 } }) {
      edges {
        node {
          id
          foreignId
          menuTitle
          slug
          regionId
          parentId
        }
      }
    }
    siteConfig {
      restaurantCount
      siteId
      currency
      bookingPrice
    }
    home: page(className: { eq: "HomePage" }) {
      ...pageFields
    }
    allCountryState {
      edges {
        node {
          id
          title
          cities {
            id
            menuTitle
            slug
            title
            restaurantCount
            scenicPhoto {
              basePath
            }
          }
        }
      }
    }
  }

  fragment pageFields on Page {
    foreignId
    parentId
    slug
    title
    menuTitle
    className
    metaDescription
    metaTitleFormatted
    metaTags
    ogImage
  }

  fragment restaurantFields on Restaurant {
    id
    foreignId
    cityId
    title
    slug
    availabilitySessions
    cuisines {
      edges {
        node {
          id: cuisineId
          label: cuisineTitle
        }
      }
    }
    allImages {
      edges {
        node {
          id
          url
        }
      }
    }
  }
`;
